import { useState, useContext } from "react";
import './LoginView.css';

import {
    useNavigate
} from "react-router-dom";

// contexts
import { UserContext } from "../Contexts/UserContext";

// hooks
import TosAPI from "../Hooks/TosAPI";

// utils
import useVerifyUser from "../Util/VerifyUser.js";

// Terms acceptance login page
const TOS_VERSION = "v1";
const PP_VERSION = "v1";
function TosView() {
    const [user, setUser] = useContext(UserContext);
    const [tos, setTos] = useState(false);
    const [pp, setPp] = useState(false);

    // Verify that we should be here
    useVerifyUser();

    // Login the user
    const navigate = useNavigate();
    const finishAcceptance = (response) => {
        let newUser = {...user};
        newUser.accepted_privacy_policy_version = PP_VERSION;
        newUser.accepted_terms_of_service_version = TOS_VERSION;
        setUser(newUser);

        navigate("/");
    }
    const {
        isLoading,
        error,
        acceptance,
    } = TosAPI(finishAcceptance);
    const doAcceptance = () => {
        if(tos && pp) {
            try {
                acceptance(TOS_VERSION, PP_VERSION);
            } catch (e) { console.error(e) }
        }
    }

    return (
        <div className="login-form text-center vh-100">
            <div className="form-signin">
            <div className="mb-5">
                <img src="img/sleuth_logo.png"  className="img-fluid" alt="Sleuth Insights"/>
            </div>
                <p className="h5 mb-5 font-weight-normal">Welcome to Sleuth! Please accept our legal terms before proceeding:</p>
                {(error ? (
                    <div classNam="alert alert-danger" role="alert">
                        {error.message}
                    </div>
                ) : <></>)}
                <div className="text-start">
                    <input className="form-check-input" 
                           type="checkbox" 
                            value={tos}
                            onChange={e => {
                                setTos(!tos)
                            }}
                           id="acceptTos" />
                    <label className="form-check-label ms-2" htmlFor="acceptTos">
                        I accept the <a href="https://sleuthinsights.com/#termsofuse" target="_blank">Terms of Service</a>
                    </label>
                </div>
                <div className="text-start">
                    <input className="form-check-input" 
                           type="checkbox" 
                           value={pp}
                           onChange={e => {
                               setPp(!pp)
                           }}
                           id="acceptPP" />
                    <label className="form-check-label ms-2" htmlFor="accept PP">
                        I accept the <a href="https://sleuthinsights.com/#privacypolicy" target="_blank">Privacy Policy</a>
                    </label>
                </div>
                <button className="btn btn-lg btn-success btn-block mt-3" disabled={!tos || !pp} type="submit" onClick={doAcceptance}>Proceed</button>
                <p className="mt-5 text-muted sleuthSize">Sleuth Insights, Inc. 2024</p>
            </div>
        </div>
    );
}

export default TosView;