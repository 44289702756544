import logo from './logo.svg';
import './App.css';
import PageContainer from './Components/PageContainer';

import { UserContext, UserContextProvider } from "./Contexts/UserContext.js";
import { TrialContext, TrialContextProvider } from "./Contexts/TrialContext.js";
import { SimulationResultContext, SimulationResultContextProvider } from "./Contexts/SimulationResultContext.js";
import { SimulationConfigContext, SimulationConfigContextProvider } from "./Contexts/SimulationConfigContext.js";
import { ScenariosContext, ScenariosContextProvider } from "./Contexts/ScenariosContext.js";

function App() {
  return (
    <>
      <UserContextProvider>
        <TrialContextProvider>
          <ScenariosContextProvider>
            <SimulationResultContextProvider>
              <SimulationConfigContextProvider>
                <PageContainer />
              </SimulationConfigContextProvider>
            </SimulationResultContextProvider>
          </ScenariosContextProvider>
        </TrialContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
