// Displays the logo of a given sponsor
function SponsorLogos({ sponsors, max }) {
    let cleanNames = [];
    if (sponsors) cleanNames = [...sponsors];

    // make sure names are lower case
    cleanNames = cleanNames.map(s => s.toLowerCase());
    // replace spaces with underscores
    cleanNames = cleanNames.map(s => s.replaceAll(' ', '_'));
    // replace &
    cleanNames = cleanNames.map(s => s.replaceAll('\&\_', ''));

    return (
        <>
            {cleanNames.map((sp) => (
                <img src={`/img/${sp}_logo.png`} className="img-fluid rounded-start m-1" alt="..." style={{ width: "100%", maxWidth: "100px" }} key={sp} />
            ))}
        </>
    );
}

export default SponsorLogos;