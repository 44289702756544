import { useState, useContext, useEffect } from "react";
import {
    Link, useNavigate
} from "react-router-dom";

// Progress Bar (Material UI)
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

// contexts
import { TrialContext } from "../Contexts/TrialContext.js";
import { ScenariosContext } from "../Contexts/ScenariosContext.js";

// components
import TrialHeader from './Sub/TrialHeader.js';

// apis
import BulkSimulationAPI from "../Hooks/BulkSimulationAPI.js";

// utils
import useVerifyUser from "../Util/VerifyUser.js";
import useVerifySession from '../Util/VerifySession.js';
import formatAsPercentage from '../Util/StringFormatPercentage.js';

// Show the progress of a simulation run
const TOTAL_RUNS = 100;
const DELAY_MS = 30;
const NUM_WORKERS = 1;

// Finds a give scenario in an array
function findScenario(scenarios, id) {
    for(let s of scenarios) {
        if(s.id === id) return s;
    }

    return null;
}

function ProgressView() {
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);
    const [currentScenarios, setCurrentScenarios] = useContext(ScenariosContext);
    const [progress, setProgress] = useState(0);
    const [complete, setComplete] = useState(false); 

    // Verify that we should be here
    useVerifyUser();
    useVerifySession();

    const navigate = useNavigate();
    const finished = (results) => {
        let newScenarios = [...currentScenarios];

        for(let r of results) {
            let s = findScenario(newScenarios, r.id);
            s.outputs = r.data;
        }

        setCurrentScenarios(newScenarios);
        setComplete(true);
        //if (progress >= TOTAL_RUNS) {
            navigate("/scenarioSummary");
        //}
    }

    // run the simulation
    const {
        isLoading,
        error,
        runSimulation,
    } = BulkSimulationAPI(currentTrial.trialName, finished);
    useEffect(() => {
        try {
            let scenariosWithoutResults = currentScenarios.filter((s) => (s.outputs === null));
            runSimulation(scenariosWithoutResults);
        } catch (e) { console.error(e) }
    }, [
        runSimulation
    ]);

    // Progress bar
    let timer;
    const scalingFactor = Math.ceil(currentScenarios.length / NUM_WORKERS);
    useEffect(() => {
        let prog = 0;
        timer = setInterval(() => {
            if (prog >= TOTAL_RUNS && complete) {
                navigate("/scenarioSummary");
            }
            else {
                setProgress((prevProgress) => (prevProgress >= TOTAL_RUNS ? prevProgress : prevProgress + (TOTAL_RUNS / 100)));
                prog += (TOTAL_RUNS / 100);
            }
        }, (DELAY_MS * scalingFactor));
        return () => {
            clearInterval(timer);
        };
    }, [currentScenarios, complete]);

    return (
        <>
            <div className="container-fluid pt-3">
                <div className="row border-bottom border-dark">
                    <div className="col-12">
                        <TrialHeader trial={currentTrial} />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 text-center">
                        <h2>Running Simulations</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="offset-1 col-10 text-center">
                        <LinearProgress variant="determinate" value={progress} sx={{
                            height: 30
                        }} />
                        <Typography variant="body2" color="text.secondary">{formatAsPercentage(progress / TOTAL_RUNS)}</Typography>
                    </div>
                </div>
                <div className="row mt-1 mb-5">
                    <div className="col-12 text-center">
                        {progress * 10} / 1,000 Completed
                    </div>
                </div>
                { error ?
                    <>
                        <div className="row mt-1 mb-5">
                            <div className="col-12 text-center">
                                <h3 className="text-danger">Error During Simulations</h3>
                                <p>Your simulations did not complete due to an internal error. Please go back and try again.</p>
                            </div>
                        </div>
                        <div className="row mt-1 mb-5">
                            <div className="col-12 text-center">
                                <Link to={`/scenarioBuilder/${currentTrial.trialName}`} className="btn btn-warning sleuthActionButton">Go Back</Link>
                            </div>
                        </div>
                    </>
                    : <></>}
            </div>
        </>
    );

}

export default ProgressView;