import {
    useState,
    useCallback,
  } from 'react';
  
  import getJSON from './GetJSON.js';
  import NetworkSettings from './NetworkSettings.js';
  
  // API Endpoint
  const LOGIN_API_URL = NetworkSettings.SERVER_URL + '/login';

  // Login the current user
  function LoginAPI(handleResult) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const apiURL = new URL(LOGIN_API_URL);
  
    const login = async (email, password, options = {}) => {
      try {
        setIsLoading(true);
        const result = await getJSON(apiURL.href, {
          method: "POST",
          body: JSON.stringify({
            username: email,
            password: password
         })
        });
        handleResult(result, email);
        return result;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        //throw e;
      }
    };
  
    return {
      isLoading,
      error,
      login: useCallback(login, []), // to avoid infinite calls when inside a `useEffect`
    };
  };
  
  export default LoginAPI;