import React, { createContext, useState, useEffect } from "react";

// Context for storing the currently active simulation slider configuration.
const SimulationConfigContext = createContext({});

const SimulationConfigContextProvider = ({ children }) => {
  const [currentSimulationConfig, setCurrentSimulationConfig] = useState({ sliders: [] });

  return (
    <SimulationConfigContext.Provider value={[currentSimulationConfig, setCurrentSimulationConfig]}>
      {children}
    </SimulationConfigContext.Provider>
  );
};

export { SimulationConfigContext, SimulationConfigContextProvider };