// Shows an error message
function ErrorDisplay({error}) {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 h3">
                    Error Occurred
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-danger font-monospace">
                    <p className="fs-6">{error.message}</p>
                </div>
            </div>
        </div>
        );
}

export default ErrorDisplay;