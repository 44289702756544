import {
  useState,
  useCallback,
} from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const TRIAL_API_URL = NetworkSettings.SERVER_URL + '/trial-data';

// Retrieve a series of trials
function BulkTrialAPI(trials, handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  
  const getTrials = async (options = {}) => {
    try {
      setIsLoading(true);
      
      const dataArray = [];
      for (const trial of trials) {
          const apiURL = new URL(TRIAL_API_URL);
          apiURL.searchParams.append("trial", trial);

          const data = await getJSON(apiURL.href, options);
          dataArray.push(data);
      }

      handleResult(dataArray);
      setIsLoading(false);
      return dataArray;
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    getTrials: useCallback(getTrials, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export default BulkTrialAPI;