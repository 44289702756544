import { useState, useContext, useEffect } from "react";
import {
    Link, useNavigate
} from "react-router-dom";

// contexts
import { TrialContext } from "../Contexts/TrialContext.js";
import { SimulationConfigContext } from "../Contexts/SimulationConfigContext.js";
import { SimulationResultContext } from "../Contexts/SimulationResultContext.js";
import { ScenariosContext } from "../Contexts/ScenariosContext.js";

// components
import TrialHeader from './Sub/TrialHeader.js';
import ErrorDisplay from './Sub/ErrorDisplay.js';

// apis
import ScenarioAPI from "../Hooks/ScenarioAPI.js";

// utils
import useVerifyUser from "../Util/VerifyUser.js";
import useVerifySession from '../Util/VerifySession.js';
import formatAsNumber from "../Util/StringFormatNumber.js";


// Finds the 50th percentile in a series
function find50th(series) {
    let result = {
        medianMonths: 'Not Found',
        highMonths: 'Not Found',
        lowMonths: 'Not Found'
    }

    let minDiff = 100.0;
    let medians = [];
    for (let s of series) {
        if (s.percentage === 0.5) {
            return s;
        }
        if (Math.abs(0.5 - s.percentage) < minDiff) {
            if (medians.length > 1) medians.shift();
            medians.push(s);
            minDiff = Math.abs(0.5 - s.percentage);
        }
    }
    if (medians.length === 0) return result;
    if (medians.length === 1) {
        return medians[0];
    }
    if (medians.length === 2) {
        return {
            medianMonths: (medians[0].medianMonths + medians[1].medianMonths) / 2.0,
            highMonths: (medians[0].highMonths + medians[1].highMonths) / 2.0,
            lowMonths: (medians[0].lowMonths + medians[1].lowMonths) / 2.0,
        }
    }

    return result;
}

function ShowMedian({series, field}) {
    let values = [];
    series.map((r) => {
        values.push(r[field]);
    });

    const sorted = values.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    
    if (sorted.length % 2 === 0) {
        return formatAsNumber((sorted[middle - 1] + sorted[middle]) / 2, "N/A");
    } else {
        return formatAsNumber(sorted[middle], "N/A");
    }
}

function ScenarioSummaryView() {
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);
    const [currentScenarios, setCurrentScenarios] = useContext(ScenariosContext);
    const [resultsTable, setResultsTable] = useState([]);

    // Verify that we should be here
    useVerifyUser();
    useVerifySession();

    function buildTable() {
        let results = [];
        currentScenarios.map((scenario) => {
            const intervention50 = find50th(scenario.outputs.pfsKMCurve.intervention);
            const comparator50 = find50th(scenario.outputs.pfsKMCurve.comparator);

            results.push(
                {
                    id: scenario.id,
                    name: scenario.name,
                    intervention: intervention50.medianMonths,
                    interventionHigh: intervention50.lowMonths,
                    interventionLow: intervention50.highMonths,
                    comparator: comparator50.medianMonths,
                    comparatorLow: comparator50.lowMonths,
                    comparatorHigh: comparator50.highMonths,
                    hazardRatio: scenario.outputs.hazardRatio.hazardRatio,
                    hazardRatioLow: scenario.outputs.hazardRatio.confidenceIntervalLower,
                    hazardRatioHigh:  scenario.outputs.hazardRatio.confidenceIntervalUpper,
                    scenario: scenario
                }
            );
        }
        )

        setResultsTable(results.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
    }

    useEffect(() => {
        buildTable();
    }, [currentScenarios]);

    const saveScenario = (scenario) => {
        const scenarioSaver = new ScenarioAPI();
        scenarioSaver.save(scenario, () => {
            console.log("Save successful for: " + scenario.id);

            let newScenarios = [...currentScenarios];
            newScenarios.map((s) => {
                if(s.id === scenario.id) {
                    s.saved = true;
                }
            });
            setCurrentScenarios(newScenarios);
        }, (error) => {
            console.log("Unable to delete scenario", error);
        });
    }

    return (
        <>
            <div className="container-fluid pt-3">
                <div className="row border-bottom border-dark">
                    <div className="col-9">
                        <TrialHeader trial={currentTrial} />
                    </div>
                    <div className="col-3">
                        <Link to={`/scenarioBuilder/${currentTrial.trialName}`} className="btn btn-warning sleuthActionButton">Update Scenarios</Link>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        Based on <strong>1,000</strong> simulations of this trial using the specified parameters, these were the outcomes:
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-center">
                        <table className="table table-sm mt-2 table-hover sleuthSize">
                            <thead>
                                <tr>
                                    <th scope="col" className="col-1">Scenario</th>
                                    <th scope="col" className="col-1">Intervention mPFS</th>
                                    <th scope="col" className="col-1">Intervention PFS range</th>
                                    <th scope="col" className="col-1">Comparator mPFS</th>
                                    <th scope="col" className="col-1">Comparator PFS range</th>
                                    <th scope="col" className="col-1">Simulated Hazard Ratio</th>
                                    <th scope="col" className="col-1"></th>
                                    <th scope="col" className="col-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultsTable.map((result) =>
                                    <tr key={result.id}>
                                        <th>{result.name}</th>
                                        <td>{formatAsNumber(result.intervention, "NR")}</td>
                                        <td>{formatAsNumber(result.interventionHigh, "NR")} to {formatAsNumber(result.interventionLow, "NR")}</td>
                                        <td>{formatAsNumber(result.comparator, "NR")}</td>
                                        <td>{formatAsNumber(result.comparatorLow, "NR")} to {formatAsNumber(result.comparatorHigh, "NR")}</td>
                                        <td>{formatAsNumber(result.hazardRatio, "N/A")} ({formatAsNumber(result.hazardRatioLow, "N/A")}, {formatAsNumber(result.hazardRatioHigh, "N/A")})</td>
                                        <td><Link to={`/scenarioResults/${result.id}`} className="btn btn-sm btn-outline-secondary"><i className="bi bi-graph-up"></i> Chart</Link></td>
                                        {result.scenario.saved ? 
                                        <td>Saved</td>
                                        : <td><button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => saveScenario(result.scenario)}><i className="bi bi-database-fill-add"></i> Save</button></td>
}
                                    </tr>
                                )}
                                <tr className="table-secondary">
                                    <th>Median</th>
                                    <td><ShowMedian series={resultsTable} field="intervention"/></td>
                                    <td></td>
                                    <td><ShowMedian series={resultsTable} field="comparator"/></td>
                                    <td></td>
                                    <td><ShowMedian series={resultsTable} field="hazardRatio"/></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScenarioSummaryView;