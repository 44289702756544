import { useContext, useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";
import { TrialContext } from "../Contexts/TrialContext.js";

// Verifies that we have valid session data, or sends back to home
function useVerifySession() {
    const navigate = useNavigate();
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);

    // If we don't have a trial, go back to the search page
    useEffect(() => {
        // Checking if user is not loggedIn
        if (!currentTrial || !currentTrial.nctId) {
            navigate("/search");
        }
    }, [navigate, currentTrial]);
}

export default useVerifySession;