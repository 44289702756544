import { useContext, useEffect, useState } from "react";
import {
    Link, useNavigate, useParams
} from "react-router-dom";

import Modal from '@mui/material/Modal';

// contexts
import { TrialContext } from "../Contexts/TrialContext.js";
import { ScenariosContext } from "../Contexts/ScenariosContext.js";

// components
import TrialHeader from './Sub/TrialHeader.js';
import PatientPopulationConfig from './Sub/PatientPopulationConfig.js';
import PfsKMChart from './Chart/PfsKMChart';
import PfsHistogramChart from './Chart/PfsHistogramChart';
import PfsTable from './Chart/PfsTable';

import RerunModal from "./RerunModal.js";

// hooks
import ScenarioAPI from "../Hooks/ScenarioAPI.js";

// utils
import useVerifyUser from "../Util/VerifyUser.js";
import useVerifySession from '../Util/VerifySession.js';
import deepCopy from "../Util/DeepCopy.js";
import ARMS from "../Data/Arms.js";
import SponsorLogos from "./Sub/SponsorLogos.js";

function getScenario(id, scenarios) {
    if (scenarios) {
        for (let s of scenarios) {
            if (s.id === id) return deepCopy(s);
        }
    }
    return {};
}

function ScenarioResultsView() {
    const { scenarioID } = useParams();
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);
    const [currentScenarios, setCurrentScenarios] = useContext(ScenariosContext);
    const [scenario, setScenario] = useState(getScenario(scenarioID, currentScenarios));
    const [updated, setUpdated] = useState(false);
    const [currentArm, setCurrentArm] = useState(ARMS.Intervention);

    const [openRerunModal, setOpenRerunModal] = useState(false);

    // Verify that we should be here
    useVerifyUser();
    useVerifySession();

    const selectNewScenario = (scenarioID) => {
        setScenario(getScenario(scenarioID, currentScenarios));
    }

    const handleCloseRerunModal = () => {
        setOpenRerunModal(false);
    }

    const handleChange = (newConfig) => {
        if (newConfig) {
            setScenario(newConfig);
            setUpdated(true);
        }
    }

    const handleRerun = (newResults) => {
        if (newResults) {
            let newScenario = { ...scenario };
            newScenario.outputs = newResults.data;
            setScenario(newScenario);
            setUpdated(false);
        }

        setOpenRerunModal(false);
    }

    const toggleSimulationArm = (simArm, arm) => {
        let newScenario = { ...scenario };

        // find arm and update it
        for (let a of newScenario.inputs.simulationArms[currentArm]) {
            if (a.trialName === simArm.trialName) {
                a[arm] = !a[arm];
                break;
            }
        }

        // save it
        setScenario(newScenario);
        setUpdated(true);
    }

    const saveScenario = (scenario) => {
        const scenarioSaver = new ScenarioAPI();
        scenarioSaver.save(scenario, () => {
            console.log("Save successful for: " + scenario.id);
            let newScenarios = [...currentScenarios];
            newScenarios.map((s) => {
                if(s.id === scenario.id) {
                    s.saved = true;
                }
            });
            setCurrentScenarios(newScenarios);
            setScenario(getScenario(scenario.id, currentScenarios))
        }, (error) => {
            console.log("Unable to delete scenario", error);
        });
    }

    return (
        <>
            <div className="container-fluid pt-3">
                <div className="row border-bottom border-dark">
                    <div className="col-6">
                        <TrialHeader trial={currentTrial} />
                    </div>
                    <div className="col-2">
                        {scenario.saved ?
                            <td>Saved</td>
                            :
                            <button className="btn btn-outline-success btn-sm" onClick={() => saveScenario(scenario)}>Save This Scenario</button>
                        }
                    </div>
                    <div className="col-2">
                        <div className="dropdown">
                            <button className="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                View Another Scenario
                            </button>
                            <ul className="dropdown-menu">
                                {currentScenarios.map((result) =>
                                    <li key={result.id}><a className="dropdown-item" href="#" onClick={() => selectNewScenario(result.id)}>{result.name}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="col-2">
                        <Link to="/scenarioSummary" className="btn btn-outline-secondary btn-sm">Back to All Scenarios</Link>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-center">

                        <div className="row mt-2">
                            <div className="col-12 fw-bold">
                                Progression-Free Survival
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12" style={{ minHeight: "500px" }}>
                                <PfsKMChart data={scenario.outputs} trial={currentTrial} key={scenario.id} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12" style={{ minHeight: "300px" }}>
                                <PfsHistogramChart data={scenario.outputs} trial={currentTrial} key={scenario.id} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <PfsTable data={scenario.outputs} trial={currentTrial} key={scenario.id} />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 d-grid gap-2">

                        <div className="row">
                            <div className="col-12">
                                {updated ?
                                    <button className="btn btn-warning sleuthActionButton w-100" onClick={() => { setOpenRerunModal(true) }}>Re-Run This Scenario</button> :
                                    <button className="btn btn-secondary disabled w-100">Re-Run This Scenario</button>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex w-100 justify-content-between">
                                <ul className="nav nav-tabs w-100">
                                    <li className="nav-item">
                                        <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">Scenario: <b className="text-dark">{scenario.name}</b></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`${currentArm === ARMS.Intervention ? "nav-link active" : "nav-link"}`} aria-current="page" href="#" onClick={() => setCurrentArm(ARMS.Intervention)}>Intervention Arm</a>
                                    </li>
                                    {currentTrial.sliders && currentTrial.sliders[ARMS.Comparator] && currentTrial.sliders[ARMS.Comparator].length > 0 ? 
                                    <li className="nav-item">
                                        <a className={`${currentArm === ARMS.Comparator ? "nav-link active" : "nav-link"}`} href="#" onClick={() => setCurrentArm(ARMS.Comparator)}>Comparator Arm</a>
                                    </li>
                                    : <></>}
                                </ul>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="card m-1 order border-dark col-10">
                                <div className="card-body p-1">
                                    <table className="table table-hover m-0">
                                        <tbody>
                                            {(scenario.inputs.simulationArms && scenario.inputs.simulationArms[currentArm]) ? (
                                                scenario.inputs.simulationArms[currentArm].map((t) => (
                                                    <tr key={t.trialName}>
                                                        <th scope="row" style={{ maxWidth: "50px" }}><SponsorLogos sponsors={t.sponsor} /></th>
                                                        <td>{t.trialName}</td>
                                                        <td className="text-center">
                                                            <div className="form-check form-switch d-flex justify-content-center">
                                                                <input className={`${t.intervention ? "form-check-input toggleBackgroundGreen" : "form-check-input"}`} checked={t.intervention} value={t.intervention} type="checkbox" id="flexSwitchCheckDefault" onChange={() => { toggleSimulationArm(t, ARMS.Intervention) }} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                        {!(t.interventionOnly) ?
                                                            <div className="form-check form-switch d-flex justify-content-center">
                                                                <input className={`${t.comparator ? "form-check-input toggleBackgroundGreen" : "form-check-input"}`} checked={t.comparator} value={t.comparator} type="checkbox" id="flexSwitchCheckDefault" onChange={() => { toggleSimulationArm(t, ARMS.Comparator) }} />
                                                            </div>
                                                        : <></>}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (<></>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col" className="sleuthSize text-center">Intervention</th>
                                                <th scope="col" className="sleuthSize text-center">Comparator</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <PatientPopulationConfig scenario={scenario} arm={currentArm} setScenario={handleChange} key={scenario.id} />
                    </div>
                </div>
                <Modal
                    open={openRerunModal}
                    onClose={handleCloseRerunModal}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <div><RerunModal scenario={scenario} handler={handleRerun} /></div>
                </Modal>
            </div>
        </>
    )
}

export default ScenarioResultsView;