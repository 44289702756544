// Chartjs 
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

// utils
import formatAsPercentage from '../../Util/StringFormatPercentage';

// Extracts a time series from the raw JSON
function extractTS(raw, field) {
  return raw.map((p) => (
    { x: p[field], y: p.percentage }
  ));
}

// Find the largest X value in a time series
function findMaxX(ts) {
  let maxX = ts[0].x;
  for(let point of ts) {
    if(point.x > maxX) maxX = point.x;
  }
  return maxX;
}

// Render a PFS chart
function PfsKMChart({ data, trial}) {
  if (!data || !data.pfsKMCurve) return;
  else {
    ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

    const options = {
      maintainAspectRatio : false,
      showLine: true,
      scales: {
        y: {
          title: {
            display: true,
            text: 'Percentage of Patients'
          },
          beginAtZero: true,
          ticks: {
            // Format as percentage
            callback: function (value, index, ticks) {
              return formatAsPercentage(value);
            }
          }
        },
        x: {
          title: {
            display: true,
            text: 'Months'
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            filter: item => (!item.text.includes('High') && !item.text.includes('Low'))
          }
        },
        tooltip: {
          callbacks: {
              label: function(context) {
                  let label = context.dataset.label || '';

                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.x !== null) {
                      label += context.parsed.x + ' months, '
                  }
                  if (context.parsed.y !== null) {
                      label += formatAsPercentage(context.parsed.y) + ' of patients';
                  }
                  return label;
              }
          }
      }
      }
    };

    const months = data.pfsKMCurve.maxMonthsToDisplay;

    // extract time series
    const interventionTS = extractTS(data.pfsKMCurve.intervention, 'medianMonths');
    const interventionHighTS = extractTS(data.pfsKMCurve.intervention, 'highMonths');
    const interventionLowTS = extractTS(data.pfsKMCurve.intervention, 'lowMonths');

    const comparatorTS = extractTS(data.pfsKMCurve.comparator, 'medianMonths');
    const controlHighTS = extractTS(data.pfsKMCurve.comparator, 'highMonths');
    const controlLowTS = extractTS(data.pfsKMCurve.comparator, 'lowMonths');

    // add a point to the low TS that matches the highest month in high TS to complete the shading
    // interventionLowTS.push({
    //   x: findMaxX(interventionHighTS),
    //   y: 0
    // })
    // controlLowTS.push({
    //   x: findMaxX(controlHighTS),
    //   y: 0
    // })    

    let interventionName = trial.intervention;
    if(!interventionName) interventionName = "Intervention";
    let comparatorName = trial.comparator;
    if(!comparatorName) comparatorName = "Comparator";

    // build the chart data
    const chartData = {
      datasets: [
        {
          label: interventionName,
          data: interventionTS,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 1)',
        },
        {
          label: interventionName + ' High',
          data: interventionHighTS,
          borderColor: 'rgb(53, 162, 235, 0.2)',
          backgroundColor: 'rgba(53, 162, 235, 0.2)',
        },
        {
          label: interventionName+ ' Low',
          data: interventionLowTS,
          borderColor: 'rgb(53, 162, 235, 0.2)',
          backgroundColor: 'rgba(53, 162, 235, 0.2)',
          fill: 1
        },
        {
          label: comparatorName,
          data: comparatorTS,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132, 1)',
        },
        {
          label: comparatorName+' Low',
          data: controlLowTS,
          borderColor: 'rgb(255, 99, 132, 0.2)',
          backgroundColor: 'rgb(255, 99, 132, 0.2)',
        },
        {
          label: comparatorName+' High',
          data: controlHighTS,
          borderColor: 'rgb(255, 99, 132, 0.2)',
          backgroundColor: 'rgb(255, 99, 132, 0.2)',
          fill: 4
        }
      ],
    };

    return (
      <Scatter options={options} data={chartData} key={trial.trialName}/>
    );
  }
}

export default PfsKMChart;