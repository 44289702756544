import {
  useState,
  useCallback,
} from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const TRIAL_API_URL = NetworkSettings.SERVER_URL + '/trial-data';

// Retrieve a specified trial
function TrialAPI(handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getTrial = async (trialName, options = {}) => {
    try {
      setIsLoading(true);
      const apiURL = new URL(TRIAL_API_URL);
      apiURL.searchParams.append("trial", trialName);
      const result = await getJSON(apiURL.href, options);
      handleResult(result);
      setIsLoading(false);
      return result;
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    getTrial: useCallback(getTrial, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export default TrialAPI;