class CSVBuilder {
    static similarTrialsFields = [{
        name: "Sponsor",
        field: "sponsor"
    },{
        name: "Trial Name",
        field: "trialName"
    },{
        name: "Phase",
        field: "phase"
    },{
        name: "Status",
        field: "status"
    },{
        name: "Indication",
        field: "indication"
    },{
        name: "Treatment Line",
        field: "treatmentLine"
    },{
        name: "Intervention",
        field: "intervention"
    },{
        name: "Intervention Modality",
        field: "interventionModality"
    },{
        name: "Prior Trial(s)",
        field: "priorTrials"
    },{
        name: "Efficacy (Intervention Arm)",
        field: "interventionEfficacy"
    },{
        name: "Safety (Intervention Arm)",
        field: "interventionSafety"
    },{
        name: "Prior Treatments (Intervention Arm)",
        field: "interventionPriorTreatments"
    },{
        name: "Comparator",
        field: "comparator"
    },{
        name: "Efficacy (Comparator Arm)",
        field: "comparatorEfficacy"
    },{
        name: "Biomarker",
        field: "biomarker"
    },{
        name: "Enrollment",
        field: "enrollment"
    },{
        name: "Sites",
        field: "sites"
    },{
        name: "Primary Outcome",
        field: "primaryOutcome"
    },{
        name: "Secondary Outcome",
        field: "secondaryOutcome"
    },{
        name: "Estimated Readout",
        field: "estimatedReadoutDate"
    },{
        name: "Actual Readout",
        field: "actualReadoutDate"
    },{
        name: "Masking",
        field: "masking"
    },{
        name: "Brief Summary",
        field: "briefSummary"
    }]
    static similarTrials(trials) {
        let result = "";

        CSVBuilder.similarTrialsFields.map((f) => {
            result += f.name + ',';
            trials.map((t) => {
                let val = t[f.field] + '';
                val = val.replace(/\n/g, ' '); // no returns
                result += '"' + val + '",';
            })
            result = result.slice(0, -1) + "\n";
        })

        result = result.slice(0, -1);

        return result;
    }
}

export default CSVBuilder;