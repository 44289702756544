import {
  useState,
  useCallback,
} from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const TRIAL_LIST_API_URL = NetworkSettings.SERVER_URL + '/trial-list';

// builds the displaynames for all searches
function buildDisplayNames(ps) {
  return ps.map((s) => (
    { ...s,
      displayName: s.trialName + ": " + s.sponsor + " / " + s.interventions + ": " + s.treatmentLine + " " + s.indication + " (" + s.stage + ")"
    }
  ));
}

// Retrieve a list of all available Trials
function TrialListAPI(handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = new URL(TRIAL_LIST_API_URL);

  const getTrialList = async (options = {}) => {
    try {
      setIsLoading(true);
      const list = await getJSON(apiURL.href, options);
      let result = buildDisplayNames(list.previousSearches);
      handleResult(result);
      return result;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      //throw e;
    }
  };

  return {
    isLoading,
    error,
    getTrialList: useCallback(getTrialList, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export default TrialListAPI;