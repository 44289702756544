import formatAsNumber from '../../Util/StringFormatNumber';

import ARMS from '../../Data/Arms';

// Finds the 50th percentile in a series
function find50th(series) {
    let result = {
        medianMonths: 'Not Found',
        highMonths: 'Not Found',
        lowMonths: 'Not Found'
    }

    let minDiff = 100.0;
    let medians = [];
    for(let s of series) {
        if(s.percentage === 0.5) {
            return s;
        }
        if(Math.abs(0.5-s.percentage) < minDiff) {
            if(medians.length > 1) medians.shift();
            medians.push(s);
            minDiff = Math.abs(0.5-s.percentage);
        }
    }
    if(medians.length === 0) return result;
    if(medians.length === 1) {
        return medians[0];
    }
    if(medians.length === 2) {
        return {
            medianMonths: (medians[0].medianMonths + medians[1].medianMonths)/2.0,
            highMonths: (medians[0].highMonths + medians[1].highMonths)/2.0,
            lowMonths: (medians[0].lowMonths + medians[1].lowMonths)/2.0,
        }
    }

    return result;
}

// Render a table of the 50th percentiles
function PfsTable({ data, trial}) {
    if (!data || !data.pfsKMCurve) return;
    else {
        let interventionName = trial.intervention;
        if(!interventionName) interventionName = "Intervention";
        let comparatorName = trial.comparator;
        if(!comparatorName) comparatorName = "Comparator";

        // find the 50th percentile
        const intervention50 = find50th(data.pfsKMCurve.intervention);
        const comparator50 = find50th(data.pfsKMCurve.comparator);

        return (
            <>
            <table className="table sleuthSize">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Median PFS (range)</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{interventionName}</th>
                        <td>{formatAsNumber(intervention50.medianMonths, 'NR')} ({formatAsNumber(intervention50.lowMonths, 'NR')} - {formatAsNumber(intervention50.highMonths, 'NR')})</td>
                        <td>&nbsp;</td>
                    </tr>
                    { trial.sliders && trial.sliders[ARMS.Comparator] && trial.sliders[ARMS.Comparator].length > 0 ?
                    <tr>
                        <th  scope="row">{comparatorName}</th>
                        <td>{formatAsNumber(comparator50.medianMonths, 'NR')} ({formatAsNumber(comparator50.lowMonths, 'NR')} - {formatAsNumber(comparator50.highMonths, 'NR')})</td>
                        <td>&nbsp;</td>
                    </tr>
                    : <></> }
                </tbody>
            </table>
            {  trial.sliders && trial.sliders[ARMS.Comparator] && trial.sliders[ARMS.Comparator].length > 0 && data.hazardRatio ? 
            <div className="alert alert-light text-center sleuthSize" role="alert">
                <b>Simulated Hazard Ratio (95% CI):</b> {data.hazardRatio.hazardRatio} ({data.hazardRatio.confidenceIntervalLower}, {data.hazardRatio.confidenceIntervalUpper})
            </div>
            : <></> }
            </>
        );
    }
}

export default PfsTable;
