import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const LOAD_SCENARIOS_API_URL = NetworkSettings.SERVER_URL + '/load-scenarios';
const SAVE_SCENARIO_API_URL = NetworkSettings.SERVER_URL + '/save-scenario';
const DELETE_SCENARIO_API_URL = NetworkSettings.SERVER_URL + '/delete-scenario';

class ScenarioAPI {
    constructor() {
        this.loadAPIURL = new URL(LOAD_SCENARIOS_API_URL);
        this.saveAPIURL = new URL(SAVE_SCENARIO_API_URL);
        this.deleteAPIURL = new URL(DELETE_SCENARIO_API_URL);
    }

    async loadAll(trialName, handleResult, handleError) {
        try {
            this.loadAPIURL.searchParams.append("trial", trialName);
            const result = await getJSON(this.loadAPIURL.href, {});
            handleResult(result);
            return result;
        } catch (e) {
            console.log("Error loading scenarios: " + e);
            handleError(e);
        }
    }

    async save(scenario, handleResult, handleError) {
        try {
            const result = await getJSON(this.saveAPIURL.href, {
                method: "POST",
                body: JSON.stringify({
                    scenarioId: scenario.id,
                    scenarioName: scenario.name,
                    trialName: scenario.trialName,
                    inputs: scenario.inputs,
                    outputs: scenario.outputs
                })
            });
            handleResult(result);
            return result;
        } catch (e) {
            console.log("Error saving scenario: " + e);
            handleError(e);
        }
    }

    async delete(scenario, handleResult, handleError) {
        try {
            const result = await getJSON(this.deleteAPIURL.href, {
                method: "POST",
                body: JSON.stringify({
                    trialName: scenario.trialName,
                    scenarioId: scenario.id
                })
            });
            handleResult(result);
            return result;
        } catch (e) {
            console.log("Error deleting scenario: " + e);
            handleError(e);
        }
    }
}

export default ScenarioAPI;