import { useContext } from "react";
import {
    Link
} from "react-router-dom";


// contexts
import { UserContext } from "../../Contexts/UserContext";

// hooks
import LogoutAPI from "../../Hooks/LogoutAPI";

// utils
import Verifier from "../../Util/Verifier";

// Site header
function Header() {
  const [user, setUser] = useContext(UserContext);

  // Call the logout API, and if it works kill the current user 
  const clearUser = () => {
    setUser(null);
  }
  const {
    isLoading,
    error,
    logout,
  } = LogoutAPI(clearUser);
  const handleLogout = () => {
      try {
        logout();
      } catch (e) { console.error(e) }
    }

  if (Verifier.verifyAll(user)) {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark py-0" style={{ backgroundColor: "#4A6741" }}>
        <div className="container-fluid">
          <Link className="navbar-brand logoText" to="/">Sleuth</Link>
        </div>
        <div className="d-flex align-middle">
          <div className="text-white small mx-4 d-inline align-middle">
            {user.email}
          </div>

          <div className="text-white small mx-4 d-inline">
            <button type="button" className="btn btn-outline-light btn-sm" onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </nav>
    )
  } else {
    return <></>;
  }
}

export default Header;