import { useState, useContext, useEffect } from "react";

// apis
import CTAListAPI from "../Hooks/CTAListAPI.js";

// components
import SponsorLogos from './Sub/SponsorLogos';

import ARMS from "../Data/Arms.js";

function AvailableTrials({availableTrials, selectedTrials, handler, filter}) {
    const myTrials = availableTrials.filter(item => !selectedTrials.some(compareItem => compareItem.trialName === item.trialName));

    const testFilters = (tests) => {
        for(let t of tests) {
            if(t.toLowerCase().includes(filter.toLowerCase())) return true;
        }

        return false;
    }

    return (
        myTrials.sort((a, b) => a.trialName > b.trialName ? 1 : -1)
        .filter((t) => testFilters([t.trialName, t.interventions,t.indication,t.stage].concat(t.sponsor)))
        .map((t) => (
            <tr key={'select_trial_'+t.trialName}>
                <th scope="row"><SponsorLogos sponsors={t.sponsor} /></th>
                <td>{t.trialName}</td>
                <td className="sleuthSize">{t.interventions}</td>
                <td className="sleuthSize">{t.indication} / {t.stage}</td>
                <td>
                    <button type="button" className="btn btn-outline-success btn-sm text-nowrap" onClick={() => handler(t, ARMS.Intervention)}><i className="bi bi-plus-lg"></i> Intervention</button>
                </td>
                <td>
                {!(t.interventionOnly) ?
                    <button type="button" className="btn btn-outline-success btn-sm text-nowrap" onClick={() => handler(t, ARMS.Comparator)}><i className="bi bi-plus-lg"></i> Comparator</button>
                : <></>}
                </td>
            </tr>
        ))
    );
}

// Select trial modal
function SelectTrialModal({ scenario, currentArm, handler }) {
    const [availableTrials, setAvailableTrials] = useState([]);
    const [CTAFilter, setCTAFilter] = useState('');

    const {
        isLoading,
        error,
        getTrialList,
    } = CTAListAPI(setAvailableTrials);
    useEffect(() => {
        try {
            getTrialList();
        } catch (e) { console.error(e) }
    }, [
        getTrialList,
    ]);

    return (
        <div className="container-fluid selectTrialModalStyling">
            <div className="row">
                <div className="col-12 align-middl">
                    <div className="d-flex w-100 justify-content-between">
                        <div className="p-2"><h4 id="parent-modal-title m-2">Select A Trial Arm</h4></div>
                        <div className="p-2 flex-grow-1 ">
                            <input type="text" 
                                    value={CTAFilter} 
                                    onChange={e => {
                                        setCTAFilter(e.target.value)
                                    }} 
                                    className="form-control form-control-sm" 
                                    placeholder="Filter trials arms..."></input>
                        </div>
                        <div><a href="#" onClick={() => handler()}><i className="bi bi-x-lg"></i></a></div>
                    </div>
                    <div id="parent-modal-description" className="text-center overflow-auto align-middl " style={{ maxHeight: "300px" }}>
                        <table className="table table-hover">
                            <tbody>
                                {scenario.inputs.simulationArms && scenario.inputs.simulationArms[currentArm]? 
                                <AvailableTrials availableTrials={availableTrials} selectedTrials={scenario.inputs.simulationArms[currentArm]} handler={handler} filter={CTAFilter} />
                                : <></>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectTrialModal;