// Verifies various states of the user
class Verifier {
    static DEFAULT_VERSION_VALUE = "none";

    static verifyUser(user) {
        return (user !== null);
    }

    static verifyLegal(user) {
        return (user.accepted_privacy_policy_version !== Verifier.DEFAULT_VERSION_VALUE &&
                user.accepted_terms_of_service_version !== Verifier.DEFAULT_VERSION_VALUE);
    }

    static verifyAll(user) {
        return Verifier.verifyUser(user) && Verifier.verifyLegal(user);
    }
}

export default Verifier;