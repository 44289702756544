// Formats a number string with no more than 2 decimal points
function formatAsNumber(val, def = 'NaN') {
    if(!val || val === 'NaN') return def;

    let str = val + '';
    let period = str.indexOf('.');
    if(period >= 0) {
        let end = period+2; // need two chars after period
        if(end <= str.length + 1) {
            str = str.substring(0, end);
        }
    }

    return str;
}

export default formatAsNumber;