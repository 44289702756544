import { useContext, useEffect, useState } from "react";
import {
    useParams, Link
} from "react-router-dom";

// components
import TrialHeader from './Sub/TrialHeader';
import SimulationConfig from './Sub/SimulationConfig';
import SimilarTrials from './Sub/SimilarTrials';
import ErrorDisplay from './Sub/ErrorDisplay';
import SponsorLogos from './Sub/SponsorLogos';

// utils
import useVerifyUser from "../Util/VerifyUser.js";
import useVerifySession from '../Util/VerifySession.js';
import CSVBuilder from "../Util/CSVBuilder.js";

// contexts
import { TrialContext } from "../Contexts/TrialContext.js";

// apis
import TrialAPI from "../Hooks/TrialAPI.js";
import BulkTrialAPI from "../Hooks/BulkTrialAPI.js";
import { sliderClasses } from "@mui/material";

function getCellClassName(thisValue, i, trialValue) {
    let className = "col-1";
    if(i === 0) className += " table-secondary";
    else if(trialValue && thisValue === trialValue) className += " table-success";

    return className;
}

function downloadAsCSV(trialName, similarTrials) {
    let contents = CSVBuilder.similarTrials(similarTrials);

    const element = document.createElement("a");
    const file = new Blob([contents], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = trialName + "_similar_trials.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

// Trial information page, shows all information about a trial.
function TrialCompareView() {
    const { trialName } = useParams();
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);
    const [similarTrials, setSimilarTrials] = useState([]);


    // Verify that we should be here
    useVerifyUser();
    useVerifySession();

    function moveLeft(index) {
        let newTrials = [...similarTrials];
        if (index <= newTrials.length - 1) {
            const temp = newTrials[index];
            newTrials[index] = newTrials[index - 1];
            newTrials[index - 1] = temp;
        }
        setSimilarTrials(newTrials);
    }

    function moveRight(index) {
        let newTrials = [...similarTrials];
        if (index < newTrials.length - 1) {
            const temp = newTrials[index];
            newTrials[index] = newTrials[index + 1];
            newTrials[index + 1] = temp;
        }
        setSimilarTrials(newTrials);
    }

    //const error = null;
    //get the trials 
    let trialNames = [];
    if (currentTrial && currentTrial.similarTrials) {
        trialNames = currentTrial.similarTrials.map((s) => {
            return s.trialName;
        });
        trialNames.unshift(currentTrial.trialName);
    };
    const {
        isLoading,
        error,
        getTrials,
    } = BulkTrialAPI(trialNames, setSimilarTrials);
    useEffect(() => {
        try {
            if (trialNames) {
                // load new trial
                getTrials();
            } else {
                // nothing to do, we already loaded it
            }
        } catch (e) { console.error(e) }
    }, [
        trialName, getTrials
    ]);

    if (error) {
        return (<ErrorDisplay error={error} />);
    } else if (isLoading) {
        return (
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-2">
                        Loading...
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-6">
                        <Link to={`/trial/${currentTrial.trialName}`} className="btn btn-outline-secondary">
                            Back to {currentTrial.trialName}
                        </Link>
                    </div>
                    <div className="col-6 text-end">
                        <a href="#" className="btn btn-outline-secondary" onClick={() => downloadAsCSV(currentTrial.trialName, similarTrials)}>Download as CSV</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm mt-2 table-hover sleuthSize">
                            <thead>
                                <tr>
                                    <th scope="col" className="col-1"></th>
                                    {similarTrials.map((s, i) => (
                                        <th scope="col" className={`${i === 0 ? "table-secondary col-1" : "col-1 align-bottom"}`} style={{ maxWidth: "100px" }} key={"logo_"+i}>
                                            <SponsorLogos sponsors={s.sponsor} />
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="col" className="col-1"></th>
                                    {similarTrials.map((s, i) => (
                                        <th scope="col" className={`${i === 0 ? "table-secondary col-1" : "col-1"}`} style={{ maxWidth: "100px" }} key={"logo_"+i}>
                                            <div className="d-flex justify-content-between">
                                            {(i < 2) ? <>&nbsp;</> : <a href="#" className="align-bottom text-muted" onClick={() => moveLeft(i)}><i className="bi bi-arrow-left-short"></i></a>}
                                            {(i === 0 || i === (similarTrials.length - 1)) ? <></> : <a href="#" className="align-bottom text-muted" onClick={() => moveRight(i)}><i className="bi bi-arrow-right-short"></i></a>}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    <th></th>
                                    {similarTrials.map((s, i) => (
                                        <th className={`${i == 0 ? "table-secondary" : ""}`} key={"name_"+i}>
                                            <Link to={`/trial/${s.trialName}`} className="text-dark link-underline link-underline-opacity-0">
                                            {s.trialName}
                                            </Link>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th></th>
                                    {similarTrials.map((s, i) => (
                                        <td className={`${i == 0 ? "table-secondary p-0" : "p-0"}`} key={"name_"+i}>
                                            <Link to={`/trial/${s.trialName}`} className="text-secondary link-underline link-underline-opacity-0">
                                            <span className="sleuthSize">More Details</span>
                                            </Link>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Phase</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.phase, i, currentTrial.phase)} key={"phase_"+i}>{s.phase}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Status</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.status, i, currentTrial.status)} key={"status_"+i}>{s.status}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Indication</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.indication, i, currentTrial.indication)} key={"indication_"+i}>{s.indication}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Treatment Line</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.treatmentLine, i, currentTrial.treatmentLine)} key={"tline_"+i}>{s.treatmentLine}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Intervention</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.intervention, i, currentTrial.intervention)} key={"intervention_"+i}>{s.intervention}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Intervention Modality</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.interventionModality, i, currentTrial.interventionModality)} key={"intervention_mod_"+i}>{s.interventionModality}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Prior Trial(s)</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.priorTrials, i, currentTrial.priorTrials)} key={"priors_"+i}>{s.priorTrials}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Efficacy (Intervention Arm)</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.interventionEfficacy, i, currentTrial.interventionEfficacy)} key={"efficacyia_"+i}>{s.interventionEfficacy}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Safety (Intervention Arm)</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.interventionSafety, i, currentTrial.interventionSafety)} key={"safetyia_"+i}>{s.interventionSafety}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Prior Treatments (Intervention Arm)</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.interventionPriorTreatments, i, currentTrial.interventionPriorTreatments)} key={"prioria_"+i}>{s.interventionPriorTreatments}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Comparator</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.comparator, i, currentTrial.comparator)} key={"comparator_"+i}>{s.comparator}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Efficacy (Comparator Arm)</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.comparatorEfficacy, i, currentTrial.comparatorEfficacy)} key={"efficacyca_"+i}>{s.comparatorEfficacy}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Biomarker</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.biomarker, i, currentTrial.biomarker)} key={"biomarker_"+i}>{s.biomarker}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Enrollment</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.enrollment, i, currentTrial.enrollment)} key={"enrollment_"+i}>{s.enrollment}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Sites</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.sites, i, currentTrial.sites)} key={"sites_"+i}>{s.sites}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Primary Outcome</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.primaryOutcome, i, currentTrial.primaryOutcome)} key={"poutcome_"+i}>{s.primaryOutcome}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Secondary Outcome</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.secondaryOutcome, i, currentTrial.secondaryOutcome)} key={"soutcome_"+i}>{s.secondaryOutcome}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Estimated Readout</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.estimatedReadoutDate, i, currentTrial.estimatedReadoutDate)} key={"estreadout_"+i}>{s.estimatedReadoutDate}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Actual Readout</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.actualReadoutDate, i, currentTrial.actualReadoutDate)} key={"actreadout_"+i}>{s.actualReadoutDate}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Masking</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.masking, i, currentTrial.masking)} key={"masking_"+i}>{s.masking}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Inclusion / Exclusion Criteria</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={getCellClassName(s.keyInclusionExclusionCriteria, i, currentTrial.keyInclusionExclusionCriteria)} key={"iecriteria_"+i}>{s.keyInclusionExclusionCriteria}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row" className="text-end text-nowrap">Brief Summary</th>
                                    {similarTrials.map((s, i) => (
                                        <td className={`${i == 0 ? "table-secondary" : ""}`} key={"summary_"+i}>{s.briefSummary}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialCompareView;