import {
  useState,
  useCallback,
} from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
//const SIMULATION_API_URL = 'data/simulation.json';
const SIMULATION_API_URL = NetworkSettings.SERVER_URL + '/trial-simulation';

// Run a specified simulation
function SimulationAPI(scenario, handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = new URL(SIMULATION_API_URL);

  const runSimulation = async (options = {}) => {
    try {
      setIsLoading(true);
      const data = await getJSON(apiURL.href, {
        method: "POST",
        body: JSON.stringify({
          trialName: scenario.trialName,
          sliders: scenario.inputs.sliders,
          simulationArms: scenario.inputs.simulationArms
       })}
      );
      const result = {
        id: scenario.id,
        name: scenario.name,
        data: data
      }
      handleResult(result);
      return result;
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    runSimulation: useCallback(runSimulation, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export default SimulationAPI;