import { useContext } from "react";

import {
    Link
} from "react-router-dom";

import { UserContext } from '../../Contexts/UserContext';

// Site left navigation
function LeftNav() {
    const [user, setUser] = useContext(UserContext);

    if(user) {
    return (
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white vh-100">
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                <li className="nav-item">
                    <Link to="/" className="nav-link align-middle px-0 text-nowrap">
                        <i className="fs-4 bi-house leftnav-item"></i> <span className="ms-1 d-none d-sm-inline leftnav-item">Home</span>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/search" className="nav-link align-middle px-0">
                        <i className="fs-4 bi-search leftnav-item"></i> <span className="ms-1 d-none d-sm-inline leftnav-item">Search</span>
                    </Link>
                </li> */}
                {/* <li>
                    <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                        <i className="fs-4 bi-bezier2 leftnav-item"></i> <span className="ms-1 d-none d-sm-inline leftnav-item">Extra</span></a>
                    <ul className="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                        <li className="w-100">
                            <a href="#" className="nav-link px-0 leftnav-item"> <span className="d-none d-sm-inline leftnav-item">Item</span> 1 </a>
                        </li>
                        <li>
                            <a href="#" className="nav-link px-0 leftnav-item"> <span className="d-none d-sm-inline leftnav-item">Item</span> 2 </a>
                        </li>
                    </ul>
                </li> */}
            </ul>
            {/* <hr />
            <div className="dropdown pb-4 align-bottom">
                <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/sbyrnes.png" alt="hugenerd" width="30" height="30" className="rounded-circle" />
                    <span className="d-none d-sm-inline mx-1">Sean Byrnes</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                    <li><a className="dropdown-item" href="#">New project...</a></li>
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li><a className="dropdown-item" href="#">Sign out</a></li>
                </ul>
            </div> */}
        </div>
    )} else {
        return <></>;
    }
}

export default LeftNav;