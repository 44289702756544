import { useContext, useEffect } from "react";

import {
    useNavigate
} from "react-router-dom";

// Slider (Material UI)
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

// contexts
import { SimulationConfigContext } from "../../Contexts/SimulationConfigContext.js";

// utils
import formatAsPercentage from '../../Util/StringFormatPercentage';

// Style to customize slider appearance
const SleuthSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    margin: 0,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-markLabel': {
        lineHeight: 1.2,
        fontSize: 10,
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        //background: 'unset',
        padding: 5,
        top: -5,
        //width: 32,
        //height: 32,
        //borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        //transformOrigin: 'bottom left',
        // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        // '&:before': { display: 'none' },
        // '&.MuiSlider-valueLabelOpen': {
        //     transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        // },
        // '& > *': {
        //     transform: 'rotate(45deg)',
        // },
    },
});

// Creates a slider input
function ConfigSlider({ slider, onSliderChange }) {
    // If everything is zero it's disabled
    if (slider.low === 0.0 && slider.high === 0.0 && slider.default === 0.0) {
        return (
            <div className="card border border-dark m-2 bg-transparent col-5" key={slider.label}>
                <div className="card-body">
                    <div className="card-title text-capitalize m-0">{slider.label}</div>
                    <div className="card-text">
                        <SleuthSlider
                            size="small"
                            disabled
                            defaultValue={0.0}
                            min={-1.0}
                            max={1.0}
                            value={0.0}
                        />
                    </div>
                    <div className="text-muted small">
                    This was not measured in this trial
                    </div>
                </div>
            </div>
        );
    } else { // This is a real slider
        // These are the points highlighted on the slider
        let marks = [
            {
                value: slider.low,
                label: slider.low,
            },
            {
                value: slider.default,
                label: slider.default,
            },
            {
                value: slider.high,
                label: slider.high,
            }
        ]

        // If it's a percentage, format marks 
        if (slider.label.includes("%")) {
            marks.map((e) => {
                e.label = formatAsPercentage(e.value);
            });
        }

        // Creates 100 increments in the slider
        let stepSize = (slider.high - slider.low) / 100;
        if (slider.type === "int") {
            // make sure integers increment by an interger amount, at least one
            stepSize = Math.floor(stepSize);
            if (stepSize < 1) stepSize = 1;
        } else {
            // round to 2 decimal places, but don't go to zero
            stepSize = (Math.round((stepSize + Number.EPSILON) * 100) / 100);
            if (stepSize === 0) stepSize = 0.01;
        }

        // Create value formatting function
        let valueText = (v) => { return v };
        if (slider.label.includes("%")) {
            valueText = (v) => { return formatAsPercentage(v) };
        }

        return (
            <div className="card border border-dark m-2 bg-transparent col-5" key={slider.label}>
                <div className="card-body">
                    <div className="card-title text-capitalize m-0">{slider.label}</div>
                    <div className="card-text">
                        <SleuthSlider
                            size="small"
                            defaultValue={slider.default}
                            min={slider.low}
                            max={slider.high}
                            step={stepSize}
                            name={slider.label}
                            valueLabelDisplay="auto"
                            valueLabelFormat={valueText}
                            marks={marks}
                            value={slider.value}
                            onChange={onSliderChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

// Configure the sliders of a new simulation
function SimulationConfig({ trial }) {
    const [currentSimulationConfig, setCurrentSimulationConfig] = useContext(SimulationConfigContext);

    // Sets the trial simulation values to the defaults
    const buildTrialDefaultConfig = () => {
        let newConfig = {
            trialName: trial.trialName,
            sliders: []
        }
        trial.sliders.map((s) => (
            newConfig.sliders.push({
                value: s.default,
                label: s.label,
                type: s.type,
                default: s.default,
                low: s.low,
                high: s.high
            })
        ));
        setCurrentSimulationConfig(newConfig);
    }

    // build initial config
    useEffect(() => {
        // but only if we have a trial and haven't already built a config for it
        if (trial.sliders && (!currentSimulationConfig.trialName || (currentSimulationConfig.trialName && currentSimulationConfig.trialName !== trial.trialName))) {
            buildTrialDefaultConfig();
        }
    }, [trial]);

    // handles changes to slider values
    const handleSliderChange = (event, index) => {
        const newValues = [...currentSimulationConfig.sliders];
        let slider = newValues.find(x => x.label === event.target.name);
        slider.value = event.target.value;
        setCurrentSimulationConfig({
            trialName: currentSimulationConfig.trialName,
            sliders: newValues
        });
    };

    // Trigger the simulation start
    const navigate = useNavigate();
    function runSimulation() {
        //TODO: Strip out all of the extraneous information from the config to compose the API request
        navigate("/progress");
    }

    if(currentSimulationConfig.sliders && currentSimulationConfig.sliders.length > 0) {
    return (
        <div className="container-fluid sleuthSize">
            <div className="row border border-top-0 border-bottom-0 border-dark pt-3 ps-3" style={{ backgroundColor: "#f8f9fa" }}>
                <div className="col-12 fw-bold">
                    Simulate Potential Trial Outcomes
                </div>
            </div>
            <div className="row border border-top-0 border-dark pb-3 ps-3 table-secondary" style={{ backgroundColor: "#f8f9fa" }}>
                <div className="col-12">
                    Sleuth uses advanced statistical models to show you the distribution of 
                    potential outcomes based on the parameters of the trial selected below.
                    Just select the values you want and click "Run" to start the simulations.
                </div>
            </div>
            <div className="row border-end border-start border-dark p1 justify-content-center sleuthSize" style={{ backgroundColor: "#f8f9fa"}}>
                {currentSimulationConfig.sliders.map((s) => (
                    <ConfigSlider slider={s} onSliderChange={handleSliderChange} key={s.label} />
                ))}
            </div>
            <div className="row border border-top-0 border-dark justify-content-center" style={{ backgroundColor: "#f8f9fa" }}>
                <div className="col-9 ps-5 my-3">
                    <div className="d-grid gap-2">
                            <button className="btn btn-warning border border-dark btn-sm" onClick={runSimulation}>Run</button>
                    </div>
                        {/* <li className="list-inline-item">
                            <button type="button" className="btn btn-outline-secondary btn-sm">Save</button>
                        </li> */}
                </div>
                <div className="col-3 pe-5 my-3 d-flex flex-row-reverse">
                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={buildTrialDefaultConfig}>Reset Values</button>
                </div>
            </div>
        </div>
    );
                    } else {
                        <>Simulation Coming Soon</>
                    }
}

export default SimulationConfig;