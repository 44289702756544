
import { useEffect, useState } from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

// apis
import TrialListAPI from "../Hooks/TrialListAPI.js";

// utils
import useVerifyUser from "../Util/VerifyUser.js";

// components
import ErrorDisplay from './Sub/ErrorDisplay';
import SponsorLogos from './Sub/SponsorLogos';

// Shows previous searches, if any
function AvailableTrials({ trials, error }) {
    if (error) {
        return (<ErrorDisplay error={error} />);
    } else {
        return (
            <>
                <div className="row">
                    {trials
                    .sort((a, b) => a.trialName > b.trialName ? 1 : -1)
                    .map((s) => (
                        <div className="col-sm-4 p-2" key={s.id}>
                            <Link to={`/trial/${s.trialName}`} className="link-underline link-underline-opacity-0">
                                <div className="card mb-3 h-100 activeCard" key={s.id}>
                                    <div className="row g-2 p-2">
                                        <div className="col-md-4 d-flex flex-wrap align-items-center">
                                            <SponsorLogos sponsors={s.sponsor} />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <p className="card-text m-0 fw-bold">{s.trialName}</p>
                                                <p className="card-text m-0"><small>{s.interventions}</small></p>
                                                <p className="card-text m-0"><small className="text-muted">{s.indication} / {s.stage}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                    <div className="col-sm-4">
                        <div className="card mb-3">
                            <div className="card-header">
                            <h6>Don't see a trial you want?</h6>
                            </div>
                            <div className="card-body">
                            Contact your Sleuth account manager and we'll add it for you.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

// Display the search screen.
function SearchView() {
    const [availableTrials, setAvailableTrials] = useState([]);
    const [selectedTrials, setSelectedTrials] = useState([]);

    useVerifyUser();

    const {
        isLoading,
        error,
        getTrialList,
    } = TrialListAPI(setAvailableTrials);
    useEffect(() => {
        try {
            getTrialList();
        } catch (e) { console.error(e) }
    }, [
        getTrialList,
    ]);

    // If you hit enter, open the currently selected trial
    const navigate = useNavigate();
    const openTrial = (e) => {
        if (e.key === 'Enter') {
            if (selectedTrials && selectedTrials.length > 0) {
                navigate("/trial/" + selectedTrials[0].trialName);
            }
        }
    }

    return (
        <>
            <div className="container-fluid pt-3">
                {/** 
                <div className="row">
                    <div className="col-12">
                        <Typeahead
                            id="basic-typeahead-single"
                            highlightOnlyResult={false}
                            minLength={2}
                            labelKey="displayName"
                            onChange={setSelectedTrials}
                            onKeyDown={openTrial}
                            options={previousSearches}
                            placeholder="Search for a trial..."
                            selected={selectedTrials}
                        />
                        <div className="text-muted small p-2">
                            Search by clinical trial, company, drug, phase or indication
                        </div>
                    </div>
                </div>
    **/}
                <div className="row">
                    <div className="col-12 align-middl">
                        <h4>Research, analyze and simulate clinical trial outcomes</h4>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 align-middl">
                        Select one of your available trials to get started:
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <AvailableTrials trials={availableTrials} error={error} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchView;