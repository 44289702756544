import {
  useState,
  useCallback,
} from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const CTA_LIST_API_URL = NetworkSettings.SERVER_URL + '/cta-list';

// Retrieve a list of all available Trials
function CTAListAPI(handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = new URL(CTA_LIST_API_URL);

  const getTrialList = async (options = {}) => {
    try {
      setIsLoading(true);
      const list = await getJSON(apiURL.href, options);

      handleResult(list.previousSearches);
      return list.previousSearches;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      //throw e;
    }
  };

  return {
    isLoading,
    error,
    getTrialList: useCallback(getTrialList, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export default CTAListAPI;