function tooltipGenerator(field) {
    switch(field) {
        case "number of participants": return "This measures the number of patients enrolled in the selected arm of this study";
        case "median age": return "This measures the median age of the patient enrolled in the study";
        case "median prior treatments": return "This measures how many prior lines of distinct treatments the median patient received prior to this study";
        case "% current or former smokers": return "This measures what percent of patients in this study are current or former smokers";
        case "% CNS metastases": return "This measures what percent of patients in this study presented (at baseline) with cancer that had spread to the central nervous system, predominantly the brain and spinal cord";
        case "% metastatic": return "This measures what percent of patients presented (at baseline) with cancer that has spread from where it started to another part in the body";
        case "median ECOG status": return "This measures the median ECOG score of a patient in this study. The ECOG performance scale is a measurement of how a patient's disease affects their daily living abilities. It ranges from 0 to 5 with 0 indicating fully active without restrictions and 5 indicating death";
        case "% visceral disease": return "This measures what percent of patients presented (at baseline) with malignancies that originate in the internal organs of the body. These cancers tend to be highly aggressive.";
        case "% ROW": return "This measures what percent of patients were enrolled at centers outside of major market geographies such as the United States, Western Europe and East Asia. These patients tend to have access to lower quality interventions and medical care which may impact treatment outcomes.";
        case "% squamous": return "This measures what percent of patients presented (at baseline) with cancer related to squamous cells. These cancers tend to have different responses to certain treatments than non-squamous cancers.";
        case "drug quality score": return "This variable allows the user to modify the perceived benefit of a treatment arm over the baseline estimate provided by the most similar trials / interventions. This will likely be driven by insights that emerge via diligence and allows the user to test various scenarios of over or underperformance relative to projected baseline.";
        case "% IMDC poor prognostic risk score": return "";
        case "% positive biomarker score": return "This measures the percent of patients that report >1% of a measured biomarker. The exact biomarker is listed in the trial details. Depending on the treatment, patients with a specific biomarker may perform better or worse than the overall population in that trial.";
        case "% 2+ organs with metastases": return "This measures the percent of patients with metastatic disease across 2 or more organs in the body. This implies fairly broad spread, which is generally associated with poorer outcomes.";
        case "deviation from typical censoring rate": return "Sleuth has measured the typical censoring rate across trials in this indication & setting. This slider allows the user to shift from that baseline censoring rate. Higher censoring will result in \"flatter\" curves as there are less patients at-risk to measure in the latter portion of the study timeline, and thus a few patients will dictate the shape of the KM curve.";
    }

    return "";
}

export default tooltipGenerator;