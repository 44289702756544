import { useContext, useEffect } from "react";
import {
    useParams, Link
} from "react-router-dom";

// components
import TrialHeader from './Sub/TrialHeader';
import SimulationConfig from './Sub/SimulationConfig';
import SimilarTrials from './Sub/SimilarTrials';
import ErrorDisplay from './Sub/ErrorDisplay';

// contexts
import { TrialContext } from "../Contexts/TrialContext.js";

// utils
import useVerifyUser from "../Util/VerifyUser.js";

// apis
import TrialAPI from "../Hooks/TrialAPI.js";

// Trial information page, shows all information about a trial.
function TrialView() {
    const { trialName } = useParams();
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);

    useVerifyUser();

    // get the trial
    const {
        isLoading,
        error,
        getTrial,
    } = TrialAPI(setCurrentTrial);
    useEffect(() => {
        try {
            if (currentTrial.trialName !== trialName) {
                // load new trial
                getTrial(trialName);
            } else {
                // nothing to do, we already loaded it
            }
        } catch (e) { console.error(e) }
    }, [
        trialName, getTrial
    ]);

    // If there is an error loading, show this
    // if(isLoading) {
    //     return (
    //     <div className="container-fluid">
    //         <div className="row">
    //             <div className="col-12 h3">
    //                 Loading Trial...
    //             </div>
    //         </div>
    //     </div>
    //     );
    // } else 
    if (error) {
        return (<ErrorDisplay error={error} />);
    } else {
        return (
            <div className="container-fluid pt-3">
                {/*  Trial Header */}
                <div className="row border-bottom border-dark">
                    <div className="col-12">
                        <TrialHeader trial={currentTrial} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12 p-2">
                                {/*  Trial Details */}
                                <div className="card sleuthSize">
                                    <div className="card-header">
                                        <b>Trial Details</b>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-sm">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">{currentTrial.briefSummary}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Phase</th><td>{currentTrial.phase}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Status</th><td>{currentTrial.status}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Indication</th><td>{currentTrial.indication}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Treatment Line</th><td>{currentTrial.treatmentLine}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Intervention</th><td>{currentTrial.intervention}</td>
                                                </tr>
                                                {(currentTrial.interventionModality && currentTrial.interventionModality !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Intervention Modality</th><td>{currentTrial.interventionModality}</td>
                                                    </tr>
                                                    : <></>}
                                                {(currentTrial.priorTrials && currentTrial.priorTrials !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Prior Trial(s)</th><td>{currentTrial.priorTrials}</td>
                                                    </tr>
                                                    : <></>}
                                                {(currentTrial.interventionEfficacy && currentTrial.interventionEfficacy !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Efficacy (Intervention Arm)</th><td>{currentTrial.interventionEfficacy}</td>
                                                    </tr>
                                                    : <></>}
                                                {(currentTrial.interventionSafety && currentTrial.interventionSafety !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Safety (Intervention Arm)</th><td>{currentTrial.interventionSafety}</td>
                                                    </tr>
                                                    : <></>}
                                                {(currentTrial.interventionPriorTreatments && currentTrial.interventionPriorTreatments !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Prior Treatments (Intervention Arm)</th><td>{currentTrial.interventionPriorTreatments}</td>
                                                    </tr>
                                                    : <></>}
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Comparator</th><td>{currentTrial.comparator}</td>
                                                </tr>
                                                {(currentTrial.comparatorEfficacy && currentTrial.comparatorEfficacy !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Efficacy (Comparator Arm)</th><td>{currentTrial.comparatorEfficacy}</td>
                                                    </tr>
                                                    : <></>}
                                                {(currentTrial.biomarker && currentTrial.biomarker !== "") ?
                                                    <tr>
                                                        <th scope="row" className="text-end text-nowrap">Biomarker</th><td>{currentTrial.biomarker}</td>
                                                    </tr>
                                                    : <></>}
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Enrollment</th><td>{currentTrial.enrollment}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Sites</th><td>{currentTrial.sites}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Primary Outcome</th><td>{currentTrial.primaryOutcome}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Secondary Outcome</th><td>{currentTrial.secondaryOutcome}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Estimated Readout</th><td>{currentTrial.estimatedReadoutDate}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Actual Readout</th><td>{currentTrial.actualReadoutDate}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-end text-nowrap">Masking</th><td>{currentTrial.masking}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer sleuthSize">
                                        Data sourced from <a href={"https://clinicaltrials.gov/study/" + currentTrial.nctId} target="new">
                                            <img src={`/img/ctg_logo.png`} style={{ width: "100px" }} className="img-fluid rounded-start m-0" alt="..." /></a>, medical journals, conference abstracts, corporate documents and more
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 justify-content-center">
                        <div className="row">
                            <div className="col-12 p-2">
                                <div className="card sleuthSize">
                                    <div className="card-header">
                                        <b>Simulate This Trial</b>
                                    </div>
                                    <div className="card-body">
                                        {!currentTrial.sliders || currentTrial.sliders.length === 0 ?
                                            <>
                                                <p>This trial is not included in your current subscription. Contact your Sleuth representative to add it to your account.</p>
                                            </>
                                            :
                                            <>
                                                <p>This trials has not completed, but you can explore potential outcomes through the Sleuth Simulation<sup>TM</sup> platform.</p>
                                                <Link to={`/scenarioBuilder/${currentTrial.trialName}`} className="btn btn-warning sleuthActionButton">Explore Potential Outcomes</Link>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 p-2">
                                {/** <SimulationConfig trial={currentTrial} /> **/}
                                <SimilarTrials trial={currentTrial} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialView;