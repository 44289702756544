import React, { createContext, useState } from "react";

// Context for storing all current scenarios
const ScenariosContext = createContext({});

const ScenariosContextProvider = ({ children }) => {
  const [currentScenarios, setCurrentScenarios] = useState([]);

  return (
    <ScenariosContext.Provider value={[currentScenarios, setCurrentScenarios]}>
      {children}
    </ScenariosContext.Provider>
  );
};

export { ScenariosContext, ScenariosContextProvider };