import { useState, useContext } from "react";
import './LoginView.css';

import {
    useNavigate
} from "react-router-dom";

// contexts
import { UserContext } from "../Contexts/UserContext";

// hooks
import LoginAPI from "../Hooks/LoginAPI";

// utils
import Verifier from "../Util/Verifier";

// User login page
function LoginView() {
    const [user, setUser] = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Login the user
    const navigate = useNavigate();
    const finishLogin = (response, userEmail) => {
        let newUser = {
            email: userEmail,
            accepted_privacy_policy_version: response.accepted_privacy_policy_version,
            accepted_terms_of_service_version: response.accepted_terms_of_service_version,
        };
        setUser(newUser);

        let userId = userEmail || null; 
        window.hj('identify', userId, {
            // Add your own custom attributes here. Some EXAMPLES:
            //'User': userEmail
            // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
            // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
            // 'Total purchases': 15, // Send numbers without quotes.
            // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
            // 'Last refund date': null, // Send null when no value exists for a user.
        });

        if (Verifier.verifyLegal(newUser)) {
            navigate("/");
        } else {
            navigate("/tos");
        }
    }
    const {
        isLoading,
        error,
        login,
    } = LoginAPI(finishLogin);
    const doLogin = () => {
        try {
            login(email, password);
        } catch (e) { console.error(e) }
    }

    // watch for Enter press
    const monitorTypingKey = (e) => {
        if (e.key === 'Enter') {
            doLogin();
        }
    }

    return (
        <div className="login-form text-center vh-100">
            <div className="form-signin">
                <div className="mb-5">
                    <img src="/img/sleuth_logo.png" className="img-fluid" alt="Sleuth Insights" />
                </div>
                <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
                {(error ? (
                    <div classNam="alert alert-danger" role="alert">
                        {error.message}
                    </div>
                ) : <></>)}
                <input type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    autoFocus
                    value={email}
                    onKeyDown={monitorTypingKey}
                    onChange={e => {
                        setEmail(e.target.value)
                    }} />
                <input type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={password}
                    onKeyDown={monitorTypingKey}
                    onChange={e => {
                        setPassword(e.target.value)
                    }} />
                <button className="btn btn-lg btn-success btn-block mt-3" type="submit" onClick={doLogin}>Sign in</button>
                <p className="mt-5 text-muted sleuthSize">Sleuth Insights, Inc. 2024</p>
            </div>
        </div>
    );
}

export default LoginView;