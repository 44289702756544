
import {
    Link
} from "react-router-dom";

// components
import SponsorLogos from './SponsorLogos';

// Display the trial information at the top of a page.
function TrialHeader({ trial }) {

    return (
        <>
            <ul className="list-inline mb-0">
                <li className="list-inline-item">
                    <Link to={`/trial/${trial.trialName}`} className="h3 text-decoration-none">
                        <SponsorLogos sponsors={trial.sponsor} />
                    </Link>
                </li>
                <li className="list-inline-item">
                    <Link to={`/trial/${trial.trialName}`} className="h3 text-decoration-none">
                        /
                    </Link>
                </li>
                <li className="list-inline-item">
                    <Link to={`/trial/${trial.trialName}`} className="h4 text-decoration-none">
                        {trial.trialName}
                    </Link>
                </li>
                <li className="list-inline-item">
                    <>(</><a href={"https://clinicaltrials.gov/study/" + trial.nctId} className="text-dark " target="new">{trial.nctId}</a><>)</>
                </li>
                <li className="list-inline-item">
                    {/* <Link to="/history" className="btn btn-outline-secondary btn-sm">
                        View History
                    </Link> */}
                </li>
            </ul>
        </>
    );
}

export default TrialHeader;