import React, { createContext, useState, useEffect } from "react";

// Context for storing logged in user data.
const UserContext = createContext({});
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };