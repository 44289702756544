import { useContext, useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";
import { UserContext } from "../Contexts/UserContext";

import Verifier from "./Verifier";

// Verifies that we have valid session data, or sends back to home
function useVerifyUser() {
    const navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);

    // If we don't have a trial, go back to the search page
    useEffect(() => {
        // Checking if user is not loggedIn
        if (!Verifier.verifyUser(user)) {
            navigate("/login");
        } else if (!Verifier.verifyLegal(user)) {
            navigate("/tos");
        }
    }, [navigate, user]);
}

export default useVerifyUser;