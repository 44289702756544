import { useContext } from "react";

// components
import Header from "./Nav/Header";
import LeftNav from './Nav/LeftNav';
import SearchView from './SearchView';
import TrialView from './TrialView';
import TrialCompareView from './TrialCompareView';
import ScenarioBuilderView from './ScenarioBuilderView';
import ProgressView from './ProgressView';
import ScenarioSummaryView from './ScenarioSummaryView';
import ScenarioResultsView from "./ScenarioResultsView";
import TosView from "./TosView";
import LoginView from './LoginView';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import { UserContext } from '../Contexts/UserContext';

// utils
import Verifier from "../Util/Verifier";

function PageContainer() {
    const [user, setUser] = useContext(UserContext);

    return (
        <BrowserRouter>
            <Header />
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    {( Verifier.verifyAll(user) ? 
                    <div className="col-auto col-md-2 col-xl-1 px-sm-2 px-0" style={{ backgroundColor: "#C0CDBC" }}>
                        <LeftNav />
                    </div>
                    : <></> )}
                    <div className="col p-0">
                        <Routes>
                            <Route path="/search" element={<SearchView />} />
                            <Route path="/trial/:trialName" element={<TrialView />} />
                            <Route path="/compare/:trialName" element={<TrialCompareView />} />
                            <Route path="/scenarioBuilder/:trialName" element={<ScenarioBuilderView />} />
                            <Route path="/progress" element={<ProgressView />} />
                            <Route path="/scenarioSummary" element={<ScenarioSummaryView />} />
                            <Route path="/scenarioResults/:scenarioID" element={<ScenarioResultsView />} />
                            <Route path="/login" element={<LoginView />} />
                            <Route path="/tos" element={<TosView />} />
                            <Route path="/" element={<SearchView />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default PageContainer;