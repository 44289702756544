import React, { createContext, useState, useEffect } from "react";

// Context for storing the currently selected trial.
const TrialContext = createContext({});
const TrialContextProvider = ({ children }) => {
  const [currentTrial, setCurrentTrial] = useState({});

  return (
    <TrialContext.Provider value={[currentTrial, setCurrentTrial]}>
      {children}
    </TrialContext.Provider>
  );
};

export { TrialContext, TrialContextProvider };