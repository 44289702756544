import {
    useState,
    useCallback,
  } from 'react';
  
  import getJSON from './GetJSON.js';
  import NetworkSettings from './NetworkSettings.js';
  
  // API Endpoint
  const TOS_API_URL = NetworkSettings.SERVER_URL + '/accept_terms_of_service_and_privacy_policy';

  // Update the user's TOS acceptance
  function TosAPI(handleResult) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const apiURL = new URL(TOS_API_URL);
  
    const acceptance = async (tosVersion, ppVersion, options = {}) => {
      try {
        setIsLoading(true);
        const result = await getJSON(apiURL.href, {
          method: "POST",
          body: JSON.stringify({
            terms_of_service_version: tosVersion,
            privacy_policy_version: ppVersion
         })
        });
        handleResult(result);
        return result;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        //throw e;
      }
    };
  
    return {
      isLoading,
      error,
      acceptance: useCallback(acceptance, []), // to avoid infinite calls when inside a `useEffect`
    };
  };
  
  export default TosAPI;