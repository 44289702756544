import { useContext, useEffect, useState } from "react";
import {
    useNavigate
} from "react-router-dom";
import './ScenarioBuilder.css';

// components
import TrialHeader from './Sub/TrialHeader';
import PatientPopulationConfig from './Sub/PatientPopulationConfig.js';
import ErrorDisplay from './Sub/ErrorDisplay.js';
import SponsorLogos from './Sub/SponsorLogos';
import Modal from '@mui/material/Modal';

import SelectTrialModal from "./SelectTrialModal.js";

// contexts
import { TrialContext } from "../Contexts/TrialContext.js";
import { ScenariosContext } from "../Contexts/ScenariosContext.js";

// hooks
import ScenarioAPI from "../Hooks/ScenarioAPI.js";

// utils
import useVerifyUser from "../Util/VerifyUser.js";
import Scenario from "../Data/Scenario.js";
import ARMS from "../Data/Arms.js";

const MIN_CTAS = 1; // minimum number of CTAs per simulation

// Builds scenarios for simulation
function ScenarioBuilderView() {
    const [currentTrial, setCurrentTrial] = useContext(TrialContext);
    const [scenario, setScenario] = useState(Scenario.newInstance(currentTrial));
    const [currentScenarios, setCurrentScenarios] = useContext(ScenariosContext);

    const [currentArm, setCurrentArm] = useState(ARMS.Intervention);
    const [openSelectTrialModal, setOpenSelectTrialModal] = useState(false);
    const [savedScenarios, setSavedScenarios] = useState([]);
    const [selectedScenario, setSelectedScenario] = useState(null);

    // Reset the config if we're coming from a different trial
    useEffect(() => {
        if (currentScenarios.length > 0) {
            if (currentScenarios[0].trialName !== currentTrial.trialName) {
                setCurrentScenarios([]);
            }
        }
    }, [currentScenarios]);

    // load saved scenarios
    useEffect(() => {
        const scenarioLoader = new ScenarioAPI();

        scenarioLoader.loadAll(currentTrial.trialName, (result) => {
            if (result.scenarios && result.scenarios.length > 0) {
                result.scenarios.map((r) => {
                    r.id = r.scenarioId;
                    r.name = r.scenarioName;
                    r.saved = true;
                })
                setSavedScenarios(result.scenarios);
                setSelectedScenario(result.scenarios[0].id)
            }
        }, (error) => {
            console.log("Unable to load saved scenarios", error);
        });
    }, [currentTrial]);

    // verify login
    useVerifyUser();

    const saveScenario = () => {
        let newScenario = { ...scenario };
        if (!newScenario.name || newScenario.name === "") {
            newScenario.name = "Scenario " + (currentScenarios.length + 1)
        }
        let newScenarios = currentScenarios.filter((s) => s.id !== newScenario.id);
        newScenarios.push(newScenario);
        setCurrentScenarios(newScenarios);
        setScenario(Scenario.newInstance(currentTrial));
        setCurrentArm(ARMS.Intervention);
    }

    const deleteScenario = (s) => {
        if (currentScenarios) {
            let newScenarios = currentScenarios.filter((value) => {
                return value.id !== s.id;
            });
            setCurrentScenarios(newScenarios);
        }
    }

    const loadScenario = (s) => {
        setScenario(s);
    }

    const newScenario = () => {
        setScenario(Scenario.newInstance(currentTrial));
        setCurrentArm(ARMS.Intervention);
    }

    const addTrial = (trial, arm) => {
        if (openSelectTrialModal) setOpenSelectTrialModal(false);

        if (trial) {
            // only add this if we haven't already
            const existingTrial = scenario.inputs.simulationArms[currentArm].filter(sa => sa.trialName === trial.trialName);
            if (!existingTrial || existingTrial.length === 0) {
                // Add a trial to the current scenario
                let newScenario = { ...scenario };
                newScenario.inputs.simulationArms[currentArm].push(
                    {
                        sponsor: trial.sponsor,
                        trialName: trial.trialName,
                        intervention: (arm === ARMS.Intervention),
                        comparator: (arm === ARMS.Comparator),
                        interventionOnly: trial.interventionOnly,
                        default: false
                    }
                )
                setScenario(newScenario);
            }
        }
    }
    const handleCloseSelectTrialModal = () => {
        setOpenSelectTrialModal(false);
    }
    const removeCTA = (cta) => {
        let newScenario = { ...scenario };;
        newScenario.inputs.simulationArms[currentArm] = newScenario.inputs.simulationArms[currentArm].filter(a => a.trialName !== cta.trialName)
        setScenario(newScenario);
    }

    const toggleSimulationArm = (simArm, arm) => {
        let newScenario = { ...scenario };

        // find arm and update it
        for (let a of newScenario.inputs.simulationArms[currentArm]) {
            if (a.trialName === simArm.trialName) {
                a[arm] = !a[arm];
                break;
            }
        }

        // save it
        setScenario(newScenario);
    }

    const loadSavedScenario = (scenario) => {
        // Make sure this scenario wasn't already added
        const exists = currentScenarios.some(s => s.id === scenario.id);
        if (!exists) {
            // it's not there so we can add it
            let newScenarios = [...currentScenarios, scenario];
            setCurrentScenarios(newScenarios);
        }
    }

    const deleteSavedScenario = (scenario) => {
        const scenarioSaver = new ScenarioAPI();
        scenarioSaver.delete(scenario, () => {
            console.log("delete successful for: " + scenario.id);

            setSavedScenarios(savedScenarios.filter((s) => (s.id !== scenario.id)));
        }, (error) => {
            console.log("Unable to delete scenario", error);
        });
    }

    const navigate = useNavigate();
    const runSimulation = () => {
        navigate("/progress");
    }

    return (
        <div className="container-fluid pt-3">
            {/*  Trial Header */}
            <div className="row border-bottom border-dark">
                <div className="col-12">
                    <TrialHeader trial={currentTrial} />
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    <div className="row">
                        <div className="col-12 p-2">
                            {/*  Trial Details */}
                            Create a Scenario for Simulation
                            <div className="card sleuthSize">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-8">
                                            <input type="text"
                                                value={scenario.name}
                                                onChange={e => {
                                                    setScenario({
                                                        ...scenario,
                                                        name: e.target.value
                                                    })
                                                }}
                                                className="form-control"
                                                id="scenarioNameInput"
                                                placeholder="Give This Scenario a Name" />
                                        </div>
                                        <div className="col-2">
                                            <button type="button" className="btn btn-sm btn-outline-success" onClick={saveScenario}>Save This Scenario</button>
                                        </div>
                                        <div className="col-2">

                                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={newScenario}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 d-flex w-100 justify-content-between">
                                            <ul className="nav nav-tabs w-100">
                                                <li className="nav-item">
                                                    <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">Select a Trial Arm to configure:</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`${currentArm === ARMS.Intervention ? "nav-link active" : "nav-link"}`} aria-current="page" href="#" onClick={() => setCurrentArm(ARMS.Intervention)}>Intervention</a>
                                                </li>

                                                {currentTrial.sliders && currentTrial.sliders[ARMS.Comparator] && currentTrial.sliders[ARMS.Comparator].length > 0 ?
                                                    <li className="nav-item">
                                                        <a className={`${currentArm === ARMS.Comparator ? "nav-link active" : "nav-link"}`} href="#" onClick={() => setCurrentArm(ARMS.Comparator)}>Comparator</a>
                                                    </li>
                                                    : <></>}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 p-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h6>Select Similar Trials for the {currentArm === ARMS.Intervention ? <b>Intervention Arm</b> : <b>Comparator Arm</b>} </h6>
                                                    <p>These are trials you believe are similar enough to {currentTrial.trialName} that their results can inform the simulated results. </p>
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col"></th>
                                                                <th scope="col" className="sleuthSize">Intervention</th>
                                                                <th scope="col" className="sleuthSize">Comparator</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(scenario.inputs.simulationArms && scenario.inputs.simulationArms[currentArm]) ? (
                                                                scenario.inputs.simulationArms[currentArm].map((t) => (
                                                                    <tr key={'cta_' + t.trialName}>
                                                                        <th scope="row"><SponsorLogos sponsors={t.sponsor} /></th>
                                                                        <td>{t.trialName}</td>
                                                                        <td>
                                                                            <div className="form-check form-switch d-flex justify-content-center">
                                                                                <input className={`${t.intervention ? "form-check-input toggleBackgroundGreen" : "form-check-input"}`} checked={t.intervention} value={t.intervention} type="checkbox" id="flexSwitchCheckDefault" onChange={() => { toggleSimulationArm(t, ARMS.Intervention) }} />
                                                                            </div>
                                                                            {t.intervention}
                                                                        </td>
                                                                        <td>
                                                                            {!(t.interventionOnly) ?
                                                                                <>
                                                                                    <div className="form-check form-switch d-flex justify-content-center">
                                                                                        <input className={`${t.comparator ? "form-check-input toggleBackgroundGreen" : "form-check-input"}`} checked={t.comparator} value={t.comparator} type="checkbox" id="flexSwitchCheckDefault" onChange={() => { toggleSimulationArm(t, ARMS.Comparator) }} />
                                                                                    </div>
                                                                                    {t.comparator}
                                                                                </>
                                                                                : <></>}
                                                                        </td>
                                                                        <td>
                                                                            {scenario.inputs.simulationArms[currentArm].length > MIN_CTAS ?
                                                                                <a href="#" onClick={() => removeCTA(t)}><i className="bi bi-x text-secondary"></i></a>
                                                                                : <></>}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (<></>)}
                                                        </tbody>
                                                    </table>
                                                    <div className="mt-1 col-12 d-grid gap-2">
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => setOpenSelectTrialModal(true)}>+ Add Another Trial</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8 p-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h6>Select Patient Population for the {currentArm === ARMS.Intervention ? <b>Intervention Arm</b> : <b>Comparator Arm</b>}</h6>
                                                    <p>Different patient populations have different influences on the potential outcomes of the trial. By selecting the patient population, you can explore how those influences affect outcomes. </p>
                                                    {(scenario.inputs.sliders && scenario.inputs.sliders[currentArm]) ?
                                                        <PatientPopulationConfig scenario={scenario} arm={currentArm} setScenario={setScenario} key={scenario.id} />
                                                        : <>Loading Sliders...</>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-3 mt-4">
                    <div className="card ">
                        <div className="card-header sleuthSize">
                            Scenarios to Simulate
                        </div>
                        <div className="card-body">
                            {currentScenarios.length > 0 ? (
                                <>
                                <table class="table table-sm table-hover">
                                <tbody>
                                    {currentScenarios.map((s) => (
                                        <tr key={s.id}>
                                            <td><a href="#" onClick={() => { loadScenario(s) }} className="text-secondary text-decoration-none">{s.name}</a></td>
                                            <td><a href="#" onClick={() => { loadScenario(s) }}><i title="Edit" className="bi bi-pencil-square text-secondary"></i></a></td>
                                            <td><a href="#" onClick={() => { deleteScenario(s) }}><i title="Delete" className="bi bi-dash-lg text-secondary"></i></a></td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>
                                <a href="#" onClick={runSimulation} className="btn btn-warning sleuthActionButton">Run All Scenarios</a>
                                </>
                            ) : (
                                <><p className="sleuthSize">Add scenarios using the form on the left, or load saved scenarios from below.</p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="card mt-5 sleuthSize">
                        <div className="card-header">
                            Your Saved Scenarios
                        </div>
                        <div className="card-body">
                            {savedScenarios && savedScenarios.length > 0 ?
                                <>
                                    <small>These are scenarios you have previously saved. Click on one to load it as part of your current simulation set.</small>
                                    
                                <table class="table table-sm table-hover">
                                <tbody>
                                        {savedScenarios.map((ss) => (
                                            <tr key={ss.id}>
                                            <td><a href="#" onClick={() => { loadSavedScenario(ss) }} className="text-secondary text-decoration-none">{ss.name}</a></td>
                                            <td><a href="#" onClick={() => { deleteSavedScenario(ss) }}><i title="Delete" className="bi bi-trash text-secondary"></i></a></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        </table>
                                </>
                                : <>
                                    <p>After you run a simulation, you can save it and load it here later.</p>
                                </>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={openSelectTrialModal}
                onClose={handleCloseSelectTrialModal}
                aria-labelledby="select-trial-modal-title"
                aria-describedby="select-trial-modal-description"
            >
                <div><SelectTrialModal scenario={scenario} handler={addTrial} currentArm={currentArm} /></div>
            </Modal>
        </div>
    );
}

export default ScenarioBuilderView;