import {
    useState,
    useCallback,
  } from 'react';
  
  import getJSON from './GetJSON.js';
  import NetworkSettings from './NetworkSettings.js';
  
  // API Endpoint
  const LOGOUT_API_URL = NetworkSettings.SERVER_URL + '/logout';

  // Logout the current user
  function LogoutAPI(handleResult) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const apiURL = new URL(LOGOUT_API_URL);
  
    const logout = async (options = {}) => {
      try {
        setIsLoading(true);
        const result = await getJSON(apiURL.href, options);
        handleResult(result);
        return result;
      } catch (e) {
        setError(e);
        setIsLoading(false);

        // TODO: Remove this when the response is valid JSON. Right now this always triggers because the response is text
        handleResult(e);
        //throw e;
      }
    };
  
    return {
      isLoading,
      error,
      logout: useCallback(logout, []), // to avoid infinite calls when inside a `useEffect`
    };
  };
  
  export default LogoutAPI;